<template>
  <div>
    <TitleMore
      :title="['备灾体系']"
      :isMore="true"
      :moreTitle="'红十字备灾体系建设一览表》'"
      @handleMore="handleMore"
    ></TitleMore>
    <div class="baseCard trainCard">

      <!-- <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;
text-align:center">{{detailData.title}}</p> -->
      <TitleLine width="200px"></TitleLine>
      <div
        id="myChart"
        class="map"
      ></div>
      <div class="trainContentCard" v-html="info">
       
      </div>
    </div>
    <el-dialog
      title="红十字备灾体系建设一览表"
      :visible.sync="dialogVisible"
      width="700">
      <el-scrollbar class="f-h-600">
        <div v-html="tableData.text">

        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import "echarts/map/js/province/sichuan";
import "echarts/map/js/china";
import apiUrls from "@api";
export default {
  data() {
    return {
      geoCoordMap: {},
      detailData:{},
	    info: '',
      tableData:{},
      dialogVisible:false
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },
  mounted() {
    this.getList("zbtx_dtsj").then((res) => {
      this.drawMap(res);
    });
	apiUrls.selfDynamicList({ node:'zbtx' }).then((res) => {
		this.info = res.results.data[0].info
	 });
  },

  methods: {
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].link_phone).concat(data[i].detail_address),
          });
        }
      }
      return res;
    },
    handleMore(){
      apiUrls.trainNum({ node: "zbtx_jsylb" }).then((res) => {
        this.tableData = res.results.data;
      });
      this.dialogVisible = true;
    },
    drawMap(data) {
		  console.log(data)
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      //选项配置
      
      var option = {
        geo: {
          show: true,
          map: "sichuan",
          itemStyle: {
            normal: {
              areaColor: "#FBFAFA",
              shadowColor: "rgba(0, 0, 0, 0.4)",
              shadowBlur: 20,
              shadowOffsetX: -30,
              shadowOffsetY: 20,
              borderWidth: 0,
            },
            emphasis: {
              areaColor: "#2AB8FF",
              borderWidth: 0,
              color: "green",
              label: {
                show: false,
              },
            },
          },
        },
        tooltip : {
          formatter:function(params){
			  console.log(params)
            if(params.componentSubType== "scatter"){
              var str = 
              `<div class="tooltipBox">
                <p style="color:#fff">名称：${params.data.name}</p>
                <p style="color:#fff">联系电话：${params.value[2]?params.value[2]:''}</p>
                <p style="color:#fff">地址：${params.value[3]?params.value[3]:''}</p>
              </div>`
              return str
            }
          }
        },
        series: [
          {
            name: "四川红十字会",
            type: "map",
            map: "sichuan", // 自定义扩展图表类型
          },
          {
            name: "四川红十字会",
            type: "scatter",
            coordinateSystem: "geo",
            /* label: {
              show: true,
              position: [-8, -15],
              formatter: (val) => {
                return val.data.name;
              },
            }, */
            data: this.convertData(data),
          },
        ],
      }
      // console.log(option)
      myChart.setOption(option);
    },
    removebg: function (value) {
      if (!value) return ''
      let arr = value.split("background-color:rgb( 255 , 255 , 255 )")
      let str = arr.join('');
      return str
    },
    getList(node) {
      return apiUrls.selfDynamicList({ node,size: '23' }).then((res) => {
        this.detailData = res.results.data[0];
        this.detailData.info = this.removebg(res.results.data[0].info)
        let pointData = [];
        res.results.data.forEach((el) => {
          let obj = {
            name: el.title,
            value: null,
            detail_address:el.detail_address,
            publishDate:el.publishDate,
            link_phone:el.link_phone
          };
          pointData.push(obj);
          this.geoCoordMap[el.title] = [el.longitude, el.latitude];
        });
		// console.log(pointData)
        return pointData;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.trainCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .trainContentCard {
    width: 1038px;
    height: auto;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 0px #fad5d6;
    font-size: 16px;
    color: #666666;
    line-height: 36px;
    padding: 20px 40px;
    background: url("../../assets/img/bg-left.png") no-repeat;
    background-size: 20% 130%;
  }
}
.f-h-600{
  height: 600px;
  /deep/ .el-scrollbar__wrap{
    overflow-x: hidden;
  }
}
.map {
  width: 482px;
  height: 415px;
  margin: 0 auto;
}
</style>